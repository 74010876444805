import React, { useState } from 'react';
import {
  Text,
  Button,
  VStack,
  Box,
  Stack,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
import {
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { useToast } from '@chakra-ui/react';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
const MyPaymentForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [payButtonLoading, setPayButtonLoading] = useState(false);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPayButtonLoading(true);
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://app.wavenightlife.com/success',
      },
    });

    if (error) {
      setPayButtonLoading(false);
      toast({
        title: 'Purchase failed.',
        description:
          'Double check your card number and try again. If the problem persists, contact Wave Support.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'bottom-right',
      });
    } else {
      const docRef = doc(db, 'Users', auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let tempPasses = docSnap.data()['passes'];

      var tempPassDetails = props.passDetails;
      
      if (props.numTickets > 1) {
        var promises = [];
        props.openDrawerFunction();

        for (let i = 0; i < props.numTickets - 1; i++) {
          const promise = fetch(
            'https://us-central1-wave-dynamics.cloudfunctions.net/adminapi/generateCode',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                authorization: '53266ec5-a432-4b2b-b0dd-30efa4f0a91a',
              },
              body: JSON.stringify({
                pass: props.passDetails,
              }),
            }
          )
            .then(response => response.json())
            .then(code => {
              return code.code; // Return the code for aggregation in the next .then
            })
            .catch(error => {
              console.error('Error:', error);
            });

          promises.push(promise);
        }

        Promise.all(promises)
          .then(async codesArray => {
            props.setCodeLoading(false);
            tempPassDetails['codes'] = [];
            for (var i = 0; i < codesArray.length; i++) {
              tempPassDetails['codes'].push(codesArray[i]);
            }
            tempPassDetails['numTickets'] = props.numTickets;
            tempPasses.push(tempPassDetails);
            await updateDoc(docRef, {
              passes: tempPasses,
              updateRequired: true,
            });
            props.closeFunction();
            setPayButtonLoading(false);
          })
          .catch(error => {
            console.error('Error in promises:', error);
          });
      } else {
        tempPassDetails['numTickets'] = props.numTickets;
        tempPasses.push(tempPassDetails);
        await updateDoc(docRef, {
          passes: tempPasses,
          updateRequired: true,
        });
      }
      

      toast({
        title: 'Purchase was successful!',
        description: 'Go to your profile page to view your passes and tickets.',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right',
      });
      props.closeFunction();
      setPayButtonLoading(false);
    }
  };
  return (
    <DrawerBody>
      <form>
        <Stack spacing="24px">
          <Box>
            <AddressElement options={{ mode: 'billing' }} />
            <PaymentElement />
          </Box>
          <DrawerFooter
            borderTopWidth="1px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack>
              <Text>
                Pass Price: $
                {(Number(props.origPrice / 100) * props.numTickets).toFixed(2)}
              </Text>
              <Text>
                Service Fee: $
                {(
                  Number(props.origPrice / 100) *
                  props.numTickets *
                  props.serviceFeeRate
                ).toFixed(2)}
              </Text>
              <Text>
                Local Sales Tax: $
                {(
                  Number(props.origPrice / 100) *
                  props.numTickets *
                  (1 + props.serviceFeeRate) *
                  props.taxRate
                ).toFixed(2)}
              </Text>
              <Button
                colorScheme="blue"
                disabled={!stripe}
                onClick={handleSubmit}
                style={{ width: '100%' }}
                isLoading={payButtonLoading}
                loadingText="Processing"
              >
                Pay ${Number(props.passPrice / 100).toFixed(2)}
              </Button>
            </VStack>
          </DrawerFooter>
        </Stack>
      </form>
    </DrawerBody>
  );
};
export { MyPaymentForm };
